const Ingredient = require('../Ingredient')

const getTotal = ({ menuItem, key, initialValue = 0 }) => {
  const ingredientKeys = menuItem.ingredientKeys
  const split = key.split(`.`)
  let total = initialValue

  for (const ingredientKey of ingredientKeys) {
    let value = Ingredient[ingredientKey]

    for (let k of split) {
      value = value[k]
    }

    if (value && value > 0) {
      total += value
    }
  }

  return total
}

const getTotalCost = ({ menuItem }) => {
  const totalCost = getTotal({ menuItem, key: `cost` })

  return Math.floor(totalCost) === totalCost ? totalCost : totalCost.toFixed(2)
}

module.exports = {
  getTotal,
  getTotalCost,
}
