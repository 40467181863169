import React from 'react'
import PropTypes from 'prop-types'
import * as API from '../../API'
import Context from './Context'

const localUserKeys = [`meals`, `baggedMeals`, `machine`, `orders`] // TODO store on server eventually

const Provider = ({ session, fetchSession, children }) => {
  const [ value, setValue ] = React.useState({ session })

  if (!value.session) {
    try {
      value.session = JSON.parse(window.localStorage.getItem(`session`)) || {
        user: {
          id: `abc123`,
          phone: `8008675309`,
          email: ``,
          firstName: `Demo`,
          lastName: ``,
          orders: []
        }
      }
    } catch (error) {
      value.session = {
        user: {
          id: `abc123`,
          phone: `8008675309`,
          email: ``,
          firstName: `Demo`,
          lastName: ``,
          orders: []
        }
      }
    }
  }

  if (!value.setSession) {
    value.setSession = session => {
      window.localStorage.setItem(`session`, JSON.stringify(session))
      setValue({ ...value, session })
    }
  }

  session = value.session

  return fetchSession ? (
    <API.Fetch postEndpoints={API.api.defaults.headers.common.Authorization ? { session: [`users/session`] } : {}}>
      {fetchProps => {
        const { session: fetchedSession, token } = (fetchProps && fetchProps.data && fetchProps.data.session && fetchProps.data.session.data) || {}
        const Authorization = token && `Bearer ${token}`

        if (fetchedSession && fetchedSession.user) {
          if (session.user) {
            localUserKeys.forEach(localUserKey => {
              if (localUserKey in session.user) {
                fetchedSession.user[localUserKey] = session.user[localUserKey]
              }
            })
          }

          if (Authorization && Authorization !== API.api.defaults.headers.common.Authorization) {
            API.api.setHeaders({ Authorization }, `userId:${fetchedSession.user.id}`)
            value.setSession(fetchedSession)
          }
        }

        return (
          <Context.Provider value={value}>
            {children}
          </Context.Provider>
        )
      }}
    </API.Fetch>
  ) : (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

Provider.propTypes = {
  session: PropTypes.object,
  fetchSession: PropTypes.bool
}

Provider.defaultProps = {
  fetchSession: true
}

export default Provider
