import React from 'react'
import styled from 'styled-components'
import { mix } from 'polished'
import octicons from 'octicons'

export const Container = ({ className, name, placeholder, value, children }) => {
  const [ state, setState ] = React.useState({ value })

  React.useEffect(() => setState({ value }), [ value ])

  return (
    <div className={className} data-placeholder={placeholder}>
      <input
        type='hidden'
        name={name}
        data-json={JSON.stringify(state.value || [])}
      />

      <ul>{children}</ul>
      <span>{placeholder}</span>
    </div>
  )
}

const List = styled(Container)`
  position: relative;
  transition: all 0.25s ease-in-out;

  &&& > ul {
    margin: 0;
    padding: 15px 0 3px 0;
    border: 0;
    border-radius: 1px;
    background: transparent;
    transition: background 0.25s ease-in-out;

    &:hover {
      background: white;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    }

    &:empty {
      height: 3px;
      padding: 0;
      margin-top: -6px;
    }

    > li {
      position: relative;
      vertical-align: middle;
      padding: 10px 3px;
      background: transparent;
      color: ${({ theme }) => mix(0.125, theme.colors.primary, `#666666`)};
      font-size: 12px;
      line-height: 1;
      transition: all 0.25s ease-in-out;

      @media (min-height: 900px) {
        padding: 9px 3px;
        font-size: 14px;
      }

      &:hover {
        background: ${({ theme }) => mix(0.125, theme.colors.primary, `#f6f6f6`)};
        color: inherit;
      }
    }

    ~ span {
      position: absolute;
      width: 100%;
      top: 3px;
      left: 3px;
      font-size: 9px;
      line-height: 1;
      white-space: nowrap;
      color: gray;
      text-transform: uppercase;
      pointer-events: none;
      transition: all 0.25s ease-in-out;

      @media (min-height: 900px) {
        font-size: 11px;
      }
    }
  }
`

List.ItemRemover = styled.button.attrs({
  type: `button`,
  dangerouslySetInnerHTML: {
    __html: octicons[`x`].toSVG()
  }
})`
  position: absolute;
  top: 7px;
  right: 3px;
  width: 18px;
  height: 18px;
  padding: 3px;
  margin: 0;
  border: 0;
  border-radius: 50%;
  background: transparent;
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  @media (min-height: 900px) {
    top: 6px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: white;
    opacity: 1;
  }
`

export default List
