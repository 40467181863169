import styled from 'styled-components'
import { mix } from 'polished'

const Button = styled.button.attrs({
  type: `button`
})`
  height: 45px;
  margin: 0;
  padding: 10px 15px;
  border: 0;
  border-radius: 3px;
  font-weight: normal;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${({ color, theme }) => theme.colors[color] || color || `white`};
  background: ${({ backgroundColor, theme }) => theme.colors[backgroundColor] || backgroundColor || theme.colors.primary};
  cursor: pointer;
  transition: color 0.25s ease-in-out, background 0.25s ease-in-out;

  &:hover {
    background: ${({ backgroundColor, theme }) => mix(0.75, theme.colors[backgroundColor] || backgroundColor || theme.colors.primary, `rgba(31, 31, 31, 1)`)};
  }

  &:active {
    background: ${({ backgroundColor, theme }) => mix(0.75, theme.colors[backgroundColor] || backgroundColor || theme.colors.primary, `rgba(0, 0, 0, 1)`)};
    transition: color 0.25s ease-in-out, background 0.25s ease-in-out;
  }

  > span {
    display: inline-block;
    vertical-align: middle;
    height: 20px;

    ~ span {
      margin-left: 6px;
      margin-right: 2px;
    }
  }
`

export default Button
