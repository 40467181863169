import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Combo from './Combo'
import Input from './Input'
import Select from './Select'
import Textarea from './Textarea'
import { getFormData } from '../utilities'

const Form = styled(({ onSubmit, onInput, getFormData, ...props }) => {
  const ref = React.createRef()

  return (
    <form
      ref={ref}

      onSubmit={event => {
        if (onSubmit) {
          onSubmit(event, getFormData(ref.current))
        }

        event.preventDefault()
        return false
      }}

      onInput={event => {
        if (onInput) {
          onInput(event, getFormData(ref.current))
        }
      }}

      { ...props }
    />
  )
})`
  > main {
    max-width: 980px;
    margin: 0 auto;
    padding: 30px 15px;
  }

  > main,
  > aside {
    ${Combo},
    ${Input},
    ${Select},
    ${Textarea} {
      width: 100%;
      margin: 0 0 15px 0;
    }
  }
`

Form.propTypes = {
  onSubmit: PropTypes.func,
  onInput: PropTypes.func,
  getFormData: PropTypes.func.isRequired
}

Form.defaultProps = {
  getFormData
}

export const ErrorMessage = styled.span`
  display: inline-block;
  vertical-align: middle;
  max-width: 400px;
  color: rgba(255, 63, 63, 0.9);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 30px;
`

Form.ErrorMessage = ({ crud, data, children }) => ((data.id && crud.status !== `error`) || data.localUpdatedAt) ? (
  <ErrorMessage>
    {children || (crud.status === `error` ? crud.message : ``)}
  </ErrorMessage>
) : null

export default Form
