import React from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import octicons from 'octicons'
import * as UI from '../../UI'
import * as Session from '../Session'
import Container from '../Container'
import Header from '../Header'

export const Timer = ({ readyAt, setReady }) => {
  const [ timeRemaining, setTimeRemaining ] = React.useState(readyAt - new Date().getTime())
  const secondsRemaining = Math.floor(timeRemaining / 1000)

  let minutes = Math.floor(secondsRemaining / 60)
  let seconds = Math.floor(secondsRemaining % 60)

  if (minutes > 0 || seconds > 0) {
    setTimeout(() => setTimeRemaining(readyAt - new Date().getTime()), 1000)
  } else {
    setReady(true)
  }

  return (
    <UI.H2>{minutes ? `${minutes} min ${seconds} sec` : `${seconds} sec`}</UI.H2>
  )
}

const History = styled(({ history, location, match, staticContext, ...props }) => {
  const [ ready, setReady ] = React.useState(false)
  const [ windowOpen, setWindowOpen ] = React.useState(false)
  const [ error, setError ] = React.useState(null)

  return (
    <Container title='Airburger Order History'>
      <Session.Context.Consumer>
        {({ session, setSession }) => {
          const user = session.user
          const orders = (user && user.orders) || []
          const orderId = (match && match.params && match.params.orderId) || (orders[0] && orders[0].id)
          const order = orders.find(order => order.id === orderId)

          if (!orderId) {
            return (
              <React.Fragment>
                <Header />

                <div { ...props }>
                  <div style={{ display: `flex`, alignItems: `center`, justifyContent: `center`, minHeight: `calc(100vh - 240px)` }}>
                    <div>
                      <UI.H3>No orders on this device.</UI.H3>

                      <UI.Button backgroundColor='red' style={{ marginTop: 30 }} onClick={() => history.goBack()}>
                        <span dangerouslySetInnerHTML={{ __html: octicons[`arrow-left`].toSVG({ height: 20 }) }} />
                        <span>Back</span>
                      </UI.Button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          }

          return (
            <React.Fragment>
              <Header />

              <div { ...props }>
                <UI.Select
                  placeholder={orders.length > 1 ? `Latest ${orders.length} Orders` : `Latest Order`}
                  value={orderId}
                  onChange={event => history.replace(`/orders/${event.target.value}`)}
                >
                  {orders.map(order => (
                    <option key={`order_option_${order.id}`} value={order.id}>
                      {new Date(order.createdAt).toDateString()} {new Date(order.createdAt).toLocaleTimeString()} @ {order.machine.title}
                    </option>
                  ))}
                </UI.Select>

                {order.pickedUpAt > 0 ? null : (
                  !ready && order.readyAt > new Date().getTime() ? (
                    <center>
                      <UI.H3>Your order will be ready in:</UI.H3>
                      <Timer readyAt={order.readyAt} setReady={setReady} />
                    </center>
                  ) : (
                    <center>
                      <UI.H3>Your order is ready at:</UI.H3>
                      <UI.H2 style={{ fontWeight: `bold` }}>Window #{order.windowIndex + 1}</UI.H2>

                      <UI.Button backgroundColor={windowOpen ? `gray` : `green`} disabled={windowOpen} onClick={async () => {
                        try {
                          setWindowOpen(true)

                          const { data: { props } } = await new Promise(resolve => {
                            setTimeout(() => {
                              const props = { ...order }

                              props.updatedAt = new Date().getTime()
                              props.pickedUpAt = props.updatedAt
                          
                              resolve({ data: { props } })
                            }, 500)
                          }) // API.api.post(`orders/${orderId}/pickup`)
                          Object.assign(order, props) // TODO fix CRUD component
                          setSession({ ...session, user: { ...user, orders: orders.map(order => order.id === orderId ? { ...order, ...props } : order) } })
                        } catch (error) {
                          setError(
                            <UI.Error>
                              <div>Something went wrong!</div>
                              <div>We've been notified.</div>
                              <div>Sorry about this. :(</div>
                            </UI.Error>
                          )
                        }
                      }}>
                        <span dangerouslySetInnerHTML={{ __html: octicons[`smiley`].toSVG({ height: 25 }) }} />
                        <span>Open Window</span>
                      </UI.Button>
                    </center>
                  )
                )}

                {error}

                <UI.UL>
                  {order.baggedMeals && order.baggedMeals.map((meal, index) => (
                    <li key={`meal_${index}`}>
                      <span>
                        {meal.title}
                      </span>

                      <span>
                        ${meal.cost.toFixed(2)}
                      </span>
                    </li>
                  ))}

                  {order.machine && (
                    <li style={{ marginTop: 30 }}>
                      <span>
                        {(order.machine.taxRate * 100).toFixed(1)}% Sales Tax
                      </span>

                      <span>
                        ${order.tax.toFixed(2)}
                      </span>
                    </li>
                  )}

                  {order.total && (
                    <li>
                      <span>
                        <UI.H2>Total Paid</UI.H2>
                      </span>

                      <span>
                        <UI.H2>${order.total.toFixed(2)}</UI.H2>
                      </span>
                    </li>
                  )}
                </UI.UL>

                {order.pickedUpAt > 0 && (
                  <div>
                    <center>
                      <UI.Button backgroundColor='green' onClick={() => {
                        setSession({ ...session, user: { ...user, baggedMeals: order.baggedMeals.map(({ id, title, menuItems }) => ({ id, title, menuItems })) } })
                        history.push(`/bag`)
                      }}>
                        <span dangerouslySetInnerHTML={{ __html: octicons[`smiley`].toSVG({ height: 25 }) }} />
                        <span>Order Again</span>
                      </UI.Button>
                    </center>
                  </div>
                )}
              </div>
            </React.Fragment>
          )
        }}
      </Session.Context.Consumer>
    </Container>
  )
})`
  > ${UI.Select} {
    width: 100%;
    margin-bottom: 30px;
  }

  > center {
    background: white;
    padding: 30px 15px;
    margin-bottom: 30px;

    > ${UI.H3} {
      margin: 15px 0;

      &:first-child {
        margin-top: 0;
      }
    }

    > ${UI.H2} {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  > ${UI.Error} {
    font-size: 20px;
    font-weight: bold;
  }

  > ${UI.UL} {
    > li {
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
      font-size: 25px;
      line-height: 1;

      > span:last-child {
        align-self: flex-end;
        text-align: right;
      }

      ${UI.H2} {
        font-size: 30px;
      }
    }
  }

  > div {
    margin: 30px 0;
    text-align: center;
  }
`

export default withRouter(History)
