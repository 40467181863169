import axios from 'axios'

const headersStorageKey = process.env.REACT_APP_HEADERS_STORAGE_KEY || `api/headers`
const endpointPrefixKey = `${headersStorageKey}/endpointPrefix`

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || `/api`,
  timeout: 120000,
  headers: process.env.REACT_APP_BASIC_AUTH ? {
    common: {
      authorization: `Basic ${btoa(process.env.REACT_APP_BASIC_AUTH)}`
    }
  } : {},
  validateStatus: status => {
    if (status === 401 && window.localStorage.getItem(headersStorageKey)) {
      api.removeHeaders()
      window.location.reload()
    }

    return status >= 200 && status < 300
  }
})

Object.assign(
  api.defaults.headers.common,
  JSON.parse(window.localStorage.getItem(headersStorageKey) || `{}`)
)

api.endpointPrefix = window.localStorage.getItem(endpointPrefixKey) || ``

api.setHeaders = (headers, endpointPrefix) => {
  headers = Object.assign(
    JSON.parse(window.localStorage.getItem(headersStorageKey) || `{}`),
    headers
  )

  Object.assign(api.defaults.headers.common, headers)
  window.localStorage.setItem(headersStorageKey, JSON.stringify(headers))
  window.localStorage.setItem(endpointPrefixKey, endpointPrefix)
  api.endpointPrefix = endpointPrefix
}

api.removeHeaders = () => {
  window.localStorage.removeItem(headersStorageKey)
  window.localStorage.removeItem(endpointPrefixKey)
  window.localStorage.removeItem(`session`)
  api.endpointPrefix = ``
}

export default api
