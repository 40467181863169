import React from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import { mix } from 'polished'
import octicons from 'octicons'
import getDistance from 'geolib/es/getDistance'
import convertDistance from 'geolib/es/convertDistance'
import { v4 as uuid } from 'uuid'
import * as UI from '../../UI'
import * as Session from '../Session'
import Container from '../Container'
import Header from '../Header'
import * as utilities from './utilities'
import machines from '../../machines'
import Ingredient from '../Ingredient'
import MenuItem from '../MenuItem'

export const UnbagButton = styled(UI.Button)`
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 25px;
  padding: 0;
  margin-right: 5px;
  text-align: center;
  background-color: transparent;

  > span {
    width: 25px;
    height: 25px;
  }

  &:hover,
  &:active {
    background-color: transparent;
  }
`

export const Checkmark = styled.div`
  position: absolute;
  top: 63px;
  left: 30px;
  width: 30px;
  height: 60px;
  transform-origin: left top;
  border-right: 3px solid ${({ theme }) => mix(0.5, theme.colors.primary || `rgba(63, 63, 63, 1)`, `rgba(63, 63, 63, 1)`)};
  border-top: 3px solid ${({ theme }) => mix(0.5, theme.colors.primary || `rgba(63, 63, 63, 1)`, `rgba(63, 63, 63, 1)`)};
  animation: checkmark .8s ease;
  transform: scaleX(-1) rotate(135deg);
  transition: opacity .4s ease-in-out;

  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: 30px;
      opacity: 1;
    }
    40% {
      width: 30px;
      height: 60px;
      opacity: 1;
    }
    100% {
      width: 30px;
      height: 60px;
      opacity: 1;
    }
  }
`

const machineIds = Object.keys(machines)

const getValidBaggedMeals = ({ baggedMeals }) => {
  const validBaggedMeals = []

  baggedMeals.forEach(({ id, title, menuItems }) => {
    menuItems = menuItems.map(({ key, ingredientKeys }) => ({
      key,
      ingredientKeys: ingredientKeys.filter(
        ingredientKey => MenuItem[key].ingredientKeys.indexOf(ingredientKey) > -1
      )
    }))

    const cost = menuItems.reduce(
      (mealTotal, menuItem) => mealTotal + menuItem.ingredientKeys.reduce(
        (menuItemTotal, ingredientKey) => menuItemTotal + (Ingredient[ingredientKey].cost || 0), 0
      ), 0
    )

    if (cost > 0) {
      validBaggedMeals.push({ id, title, menuItems, cost })
    }
  })

  return validBaggedMeals
}

const getTaxAndTotal = ({ machine, baggedMeals }) => {
  const totalWithoutTax = baggedMeals.reduce(
    (total, meal) => total + meal.menuItems.reduce(
      (mealTotal, menuItem) => mealTotal + menuItem.ingredientKeys.reduce(
        (menuItemTotal, ingredientKey) => menuItemTotal + (Ingredient[ingredientKey].cost || 0), 0
      ), 0
    ), 0
  )

  const tax = machine.taxRate * totalWithoutTax
  const total = totalWithoutTax + tax

  return { tax, total }
}

const Bag = styled(({ history, location, match, staticContext, ...props }) => {
  const [ state, setState ] = React.useState({ machineIds })
  const [ positionTimestamp, setPositionTimestamp ] = React.useState(0)

  React.useEffect(() => {
    if (state.order && state.order.id) {
      setTimeout(() => {
        history.push(`/orders/${state.order.id}`)
      }, 1000)
    }
  }, [ history, state ])

  return (
    <Container title='Airburger Bag'>
      <Session.Context.Consumer>
        {({ session, setSession }) => {
          const user = session.user
          let baggedMeals = (user && user.baggedMeals) || []
          const machine = user && user.machine
          const totalWithoutTax = baggedMeals.reduce((total, meal) => total + utilities.getTotalCost({ meal, toFixedString: false }), 0)
          const taxRate = (machine && machine.taxRate) || 0
          const tax = taxRate * totalWithoutTax
          const total = totalWithoutTax + tax

          navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude }, timestamp = positionTimestamp }) => {
            for (let machineId in machines) {
              machines[machineId].distance = convertDistance(getDistance({ latitude, longitude }, {
                latitude: machines[machineId].coordinates[0],
                longitude: machines[machineId].coordinates[1]
              }), `mi`)
            }

            machineIds.sort((a, b) => machines[a].distance - machines[b].distance)
            setPositionTimestamp(timestamp)

            if (!machine && machineIds[0]) {
              setSession({ ...session, user: { ...user, machine: machines[machineIds[0]] } })
            }
          }, () => {}, { timeout: 10000 })

          return (
            <React.Fragment>
              <Header />

              <div { ...props }>
                {(state.ordering || state.order) ? (
                  <div style={{ display: `flex`, alignItems: `center`, justifyContent: `center`, minHeight: `calc(100vh - 240px)` }}>
                    <div>
                      <UI.Spinner spin={!state.order}>
                        {state.order && <Checkmark />}
                      </UI.Spinner>

                      <UI.H2>Ordering...</UI.H2>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <UI.Select
                      id='machineId'
                      placeholder='Choose Airburger Location'
                      value={(machine && machine.id) || ``}
                      onChange={event => {
                        setSession({ ...session, user: { ...user, machine: machines[event.target.value] || null } })
                      }}
                    >
                      {!machine && (
                        <option value=''></option>
                      )}

                      {machineIds.map(machineId => {
                        const machine = machines[machineId]

                        return (
                          <option key={`machine_option_${machine.id}`} value={machine.id}>
                            {`${machine.title}${typeof machine.distance !== `undefined` ? ` (${Math.floor(machine.distance) === machine.distance ? machine.distance : machine.distance.toFixed(1)} mi)` : ``}`}
                          </option>
                        )
                      })}
                    </UI.Select>

                    {machine && (
                      <center>
                        <a href={`${/(iPhone|iPad|iPod)/.test(navigator.platform) && /safari/gi.test(navigator.userAgent) ? `maps` : `https`}://maps.google.com/maps?daddr=${machine.address}`} target='_blank' rel='noopener noreferrer'>
                          <UI.Button>
                            <span dangerouslySetInnerHTML={{ __html: octicons[`location`].toSVG({ height: 25 }) }} />
                            <span>Open in Maps</span>
                          </UI.Button>
                        </a>
                      </center>
                    )}

                    <UI.UL>
                      {baggedMeals.map((meal, index) => {
                        const cost = utilities.getTotalCost({ meal, toFixedString: true })

                        return cost > 0 && (
                          <li key={`meal_${index}`}>
                            <span>
                              <UnbagButton color='red' onClick={() => {
                                baggedMeals = [ ...baggedMeals ]
                                baggedMeals.splice(index, 1)
                                setSession({ ...session, user: { ...user, baggedMeals } })
                              }}>
                                <span dangerouslySetInnerHTML={{ __html: octicons[`x`].toSVG({ height: 25 }) }} />
                              </UnbagButton>

                              {meal.title}
                            </span>

                            <span>
                              ${cost}
                            </span>
                          </li>
                        )
                      })}

                      {total > 0 ? (
                        machine && (
                          <React.Fragment>
                            <li style={{ marginTop: 30 }}>
                              <span>
                                {(taxRate * 100).toFixed(1)}% Sales Tax
                              </span>

                              <span>
                                ${tax.toFixed(2)}
                              </span>
                            </li>

                            <li>
                              <span>
                                <UI.H2>Balance Due</UI.H2>
                              </span>

                              <span>
                                <UI.H2>${total.toFixed(2)}</UI.H2>
                              </span>
                            </li>
                          </React.Fragment>
                        )
                      ) : (
                        <li style={{ justifyContent: `center`, marginTop: `calc(20vh)` }}>
                          Your bag is empty.
                        </li>
                      )}
                    </UI.UL>

                    <div>
                      <UI.Button backgroundColor='red' style={{ width: `calc(30% - 5px)` }} onClick={() => history.goBack()}>
                        <span dangerouslySetInnerHTML={{ __html: octicons[`arrow-left`].toSVG({ height: 20 }) }} />
                        <span>Back</span>
                      </UI.Button>

                      {total > 0 && (
                        <UI.Button backgroundColor={user && user.id && machine ? `green` : `gray`} style={{ width: `calc(70% - 5px)`, marginLeft: 10 }} onClick={async () => {
                          if (!user || !user.id) {
                            document.getElementById(`Avatar`).click()
                            return
                          }

                          if (!machine) {
                            document.getElementById(`machineId`).focus()
                            return
                          }

                          try {
                            setState({ ...state, ordering: true, order: null, error: null })
                            const { data: { props } } = await new Promise(resolve => {
                              setTimeout(() => {
                                const props = { machineId: machine.id, baggedMeals, total }
                          
                                props.id = uuid()
                                props.createdAt = new Date().getTime()
                                props.updatedAt = props.createdAt
                                props.userId = user.id
                                props.machine = machine
                                props.baggedMeals = getValidBaggedMeals(props)
                          
                                Object.assign(props, getTaxAndTotal(props))
                          
                                props.windowIndex = 1
                                props.readyAt = new Date().getTime() + 53000
                                props.pickedUpAt = 0
                                
                                resolve({ data: { props } })
                              }, 500)
                            }) // API.api.post(`orders`, { machineId: machine.id, baggedMeals, total })
                            setSession({ ...session, user: { ...user, baggedMeals: [], orders: [ props ].concat(user.orders || []).slice(0, 10) } })
                            setState({ ...state, ordering: false, order: props, error: null })
                          } catch (error) {
                            setState({ ...state, ordering: false, order: null, error: (
                              <UI.Error>
                                <div>Something went wrong!</div>
                                <div>We've been notified.</div>
                                <div>Sorry about this. :(</div>
                              </UI.Error>
                            ) })
                          }
                        }}>
                          <span dangerouslySetInnerHTML={{ __html: octicons[machine ? `smiley` : `location`].toSVG({ height: 20 }) }} />
                          <span>{user && user.id ? machine ? `Order ${baggedMeals.length} Meal${baggedMeals.length === 1 ? `` : `s`}` : `Choose Location` : `Join or Login`}</span>
                        </UI.Button>
                      )}
                    </div>

                    {state.error}
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )
        }}
      </Session.Context.Consumer>
    </Container>
  )
})`
  > ${UI.Select} {
    width: 100%;
    margin-bottom: 30px;

    + center > a > ${UI.Button} {
      margin: -15px auto 45px;
    }
  }

  > ${UI.UL} {
    > li {
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
      font-size: 25px;
      line-height: 1;

      > span:last-child {
        align-self: flex-end;
        text-align: right;
      }

      ${UI.H2} {
        font-size: 30px;
      }
    }
  }

  > div {
    margin: 30px 0;
    text-align: center;
  
    > ${UI.Button} {
      padding: 10px 5px;
    }
  }

  > ${UI.Error} {
    font-size: 20px;
    font-weight: bold;
  }
`

export default withRouter(Bag)
