import React from 'react'
import styled from 'styled-components'
import { mix } from 'polished'

const Textarea = ({
  className,
  placeholder,
  value,
  onChange,
  onKeyUp,
  onKeyDown,
  onKeyPress,
  children,
  ...props
}) => {
  const [ state, setState ] = React.useState({ value })
  const handleChange = event => {
    setState({ value: event.target.value })

    if (onChange) {
      onChange(event, setState)
    }
  }

  React.useEffect(() => setState({ value }), [ value ])

  return (
    <span className={className} data-placeholder={placeholder}>
      <textarea
        { ...props }
        value={state.value}
        onChange={handleChange}
        onKeyUp={onKeyUp && (event => onKeyUp(event, setState))}
        onKeyDown={onKeyDown && (event => onKeyDown(event, setState))}
        onKeyPress={onKeyPress && (event => onKeyPress(event, setState))}
      />

      {children}

      <span>{placeholder}</span>
    </span>
  )
}

export default styled(Textarea).attrs({
  value: ``
})`
  position: relative;
  display: inline-block;
  vertical-align: middle;

  > textarea {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    min-height: 100px;
    margin: 0;
    padding: 22px 3px 5px;
    border: 1px solid transparent;
    border-bottom-color: ${({ theme }) => mix(0.1, theme.colors.primary || `rgba(223, 223, 223, 1)`, `rgba(223, 223, 223, 1)`)};
    border-radius: 1px;
    background: transparent;
    color: rgba(0, 0, 0, 0.9);
    font-size: 18px;
    font-family: inherit;
    line-height: 1.25;
    text-align: inherit;
    transition: all 0.25s ease-in-out;

    @media (min-height: 900px) {
      min-height: 150px;
      padding: 19px 3px 3px;
      font-size: 18px;
    }

    &:hover {
      border-bottom-color: ${({ theme }) => mix(0.1, theme.colors.primary || `rgba(223, 223, 223, 0.75)`, `rgba(223, 223, 223, 0.75)`)};
      background: white;
    }

    &:focus,
    &:focus:hover {
      border-bottom-color: ${({ theme }) => mix(0.5, theme.colors.primary || `rgba(127, 127, 127, 1)`, `rgba(127, 127, 127, 1)`)};
      background: white;
    }

    &:hover,
    &:focus,
    &:focus:hover {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    }

    ~ span {
      position: absolute;
      width: 100%;
      top: 3px;
      left: 3px;
      font-size: 9px;
      line-height: 1;
      white-space: nowrap;
      color: gray;
      text-transform: uppercase;
      pointer-events: none;
      transition: all 0.25s ease-in-out;

      @media (min-height: 900px) {
        font-size: 11px;
      }
    }

    &:focus ~ span {
      color: ${({ theme }) => mix(0.5, theme.colors.primary || `rgba(127, 127, 127, 1)`, `rgba(127, 127, 127, 1)`)};
      text-shadow: 0 0 ${({ theme }) => mix(0.5, theme.colors.primary || `rgba(127, 127, 127, 1)`, `rgba(127, 127, 127, 1)`)};
    }
  }
`
