import styled from 'styled-components'
import octicons from 'octicons'

const Modal = styled.div`
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 30px;
  background: white;
`

Modal.CloseButton = styled.button.attrs({
  dangerouslySetInnerHTML: { __html: octicons[`x`].toSVG({ width: 26, height: 26 }) }
})`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  padding: 7px;
  background: transparent;
  border: 0;
`

export default Modal
