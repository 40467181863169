module.exports = {
  title: `Sweet Potato`,
  image: require('./FriesSweetPotato.png'),
  height: 160,
  cost: 3,
  limit: 1,

  nutrition: {
    calories: 280,
    carbs: 48,
    fiber: 4,
    sugar: 14,
    fat: 10,
    saturatedFat: 42,
    polyunsaturatedFat: 4,
    monounsaturatedFat: 12,
    protein: 2,
    sodium: 220,
    potassium: 300,
  },

  preparation: {
    limit: 1,
    temperature: 400,
    time: 60 * 9,
  },
}
