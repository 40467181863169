import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import * as local from 'idb-keyval'
import api from './api'
import { getErrorMessage } from './utilities'
import { getDeepObject } from '../utilities'

class CRUD extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      data: props.apiData || props.data || {},
      apiData: props.apiData || {},
      status: `reading`,
      message: `Reading...`
    }

    this.create = this.create.bind(this)
    this.read = this.read.bind(this)
    this.update = this.update.bind(this)
    this.updateLocal = this.updateLocal.bind(this)
    this.delete = this.delete.bind(this)
    this.deleteLocal = this.deleteLocal.bind(this)

    this.handleInput = this.handleInput.bind(this)
    this.handleForm = this.handleForm.bind(this)
    this.setState = this.setState.bind(this)

    this.debouncedUpdates = {}
  }

  componentDidMount() {
    this.setEndpoint(!this.props.apiData)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.usingEndpointProp) {
      if (this.props.endpoint !== prevProps.endpoint) {
        this.setEndpoint()
      }
    } else if (this.prevLocation !== this.props.location) {
      this.setEndpoint()
    }
  }

  setEndpoint(shouldRead = true) {
    const { getCreateRegex, location } = this.props

    this.isCreating = typeof this.props.isCreating === `undefined` ? getCreateRegex().test(location.pathname) : this.props.isCreating
    this.usingEndpointProp = typeof this.props.endpoint !== 'undefined'
    this.endpoint = this.usingEndpointProp
      ? this.props.endpoint
      : (location.pathname.substring(1).replace(getCreateRegex(), ``) + location.search)
    this.endpointPrefix = `${api.endpointPrefix};`
    this.prevLocation = location

    if (shouldRead) {
      this.read()
    }
  }

  async create(data) {
    this.setState({ status: `creating`, message: `Creating...` })

    try {
      const apiData = this.props.localOnly ? data : (await api.post(this.endpoint, data)).data.props
      const { history } = this.props

      local.del(this.endpointPrefix + this.endpoint)  // reset local data for `create` endpoint
      this.setState({ data: { ...this.state.data, ...apiData, localUpdatedAt: 0 }, apiData, status: `ready`, message: `` })

      if (this.props.autoRedirect && apiData.id) {
        history.push(apiData.id)
      }
    } catch (error) {
      this.setState({ status: `error`, message: getErrorMessage(error) })
      throw error
    }
  }

  async read() {
    let data
    let apiData

    this.setState({ status: `reading`, message: `Reading...` })

    try {
      data = await local.get(this.endpointPrefix + this.endpoint)
      apiData = this.props.localOnly ? data : (!this.isCreating && (await api.get(this.endpoint)).data.props)

      if (!data || !data.localUpdatedAt) {
        data = apiData || this.props.data || {}
      }

      this.setState({ data, apiData, status: `ready`, message: `` })
    } catch (error) {
      this.setState({ status: `error`, message: getErrorMessage(error) })
      throw error
    }
  }

  async update(updates) {
    this.setState({ status: `updating`, message: `Saving...` })

    try {
      const apiData = this.props.localOnly ? updates : (await api.patch(this.endpoint, updates)).data.props
      const data = { ...this.state.data, ...apiData, localUpdatedAt: 0 }

      await local.set(this.endpointPrefix + this.endpoint, data)

      this.setState({ data, apiData, status: `ready`, message: `` })
    } catch (error) {
      this.setState({ status: `error`, message: getErrorMessage(error) })
      throw error
    }
  }

  async updateLocal(updates, debounce = 100) {
    const doUpdate = async () => {
      const { debouncedUpdates } = this

      this.debouncedUpdates = {}

      try {
        const data = { ...this.state.data, ...debouncedUpdates }
        this.setState({ data })
        await local.set(this.endpointPrefix + this.endpoint, data)
      } catch (error) {
        this.debouncedUpdates = { ...debouncedUpdates, ...this.debouncedUpdates }
        this.setState({ status: `error`, message: getErrorMessage(error) })
        throw error
      }
    }

    this.debouncedUpdates = {
      ...this.debouncedUpdates,
      ...updates,
      localUpdatedAt: (new Date()).toISOString()
    }

    clearTimeout(this.updateLocalTimeout)

    if (debounce) {
      this.updateLocalTimeout = setTimeout(doUpdate, debounce)
    } else {
      doUpdate()
    }
  }

  async delete() {
    this.setState({ status: `deleting`, message: `Deleting...` })

    try {
      await local.del(this.endpointPrefix + this.endpoint)

      if (!this.props.localOnly) {
        await api.delete(this.endpoint)
      }

      this.setState({ data: {}, apiData: {}, status: `deleted`, message: `` })
    } catch (error) {
      this.setState({ status: `error`, message: getErrorMessage(error) })
      throw error
    }
  }

  async deleteLocal() {
    clearTimeout(this.updateLocalTimeout)
    this.setState({ status: `discarding`, message: `Discarding...` })

    try {
      await local.del(this.endpointPrefix + this.endpoint)

      this.setState({ data: this.state.apiData || this.props.apiData || this.props.data || {}, status: `ready`, message: `` })
    } catch (error) {
      this.setState({ status: `error`, message: getErrorMessage(error) })
      throw error
    }
  }

  async handleInput(event) {
    const originalTarget = event.target

    if (event.target.tagName === `OPTION`) {
      while (event.target && !event.target.name) {
        event.target = event.target.parentNode
      }

      if (!event.target) {
        event.target = originalTarget
      }
    }

    const { name, value } = event.target

    if (name) {
      await this.updateLocal(
        getDeepObject(name, value, { ...this.state.data }),
        event.__debounce
      )
    }
  }

  async handleForm(event, formData) {
    if (this.isCreating) {
      await this.create(formData)
    } else {
      await this.update(formData)
    }
  }

  render() {
    return this.props.children({
      ...this.state,
      isCreating: this.isCreating,
      endpoint: this.endpoint,
      endpointPrefix: this.endpointPrefix,

      create: this.create,
      read: this.read,
      update: this.update,
      updateLocal: this.updateLocal,
      delete: this.delete,
      deleteLocal: this.deleteLocal,

      handleInput: this.handleInput,
      handleForm: this.handleForm,
      setState: this.setState
    })
  }
}

CRUD.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  endpoint: PropTypes.string,
  isCreating: PropTypes.bool,
  getCreateRegex: PropTypes.func.isRequired,
  autoRedirect: PropTypes.bool,
  localOnly: PropTypes.bool,
  children: PropTypes.func.isRequired
}

CRUD.defaultProps = {
  getCreateRegex: () => /\/create$/,
  autoRedirect: true
}

export default withRouter(CRUD)
