module.exports = {
  title: `Mayo`,
  image: require('./Mayo.png'),
  height: 35,
  limit: 4,

  nutrition: {
    servingSize: `0.5 tbsp`,
    calories: 100,
    fat: 10,
    saturatedFat: 2,
    polyunsaturatedFat: 6,
    monounsaturatedFat: 2,
    sodium: 30,
    cholesterol: 5,
  },

  collapsedStyle: {
    maxWidth: `60%`,
  },

  preparation: {
    limit: 4,
  },
}
