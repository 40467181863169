module.exports = {
  title: `Beef`,
  image: require('./PattyBeef.png'),
  height: 80,
  cost: 3,
  limit: 4,

  nutrition: {
    calories: 240,
    fat: 17,
    saturatedFat: 7,
    protein: 21,
    sodium: 75,
    cholesterol: 75,
    calcium: 2,
    iron: 15,
  },

  collapsedStyle: {
    maxWidth: `88%`,
  },

  preparation: {
    limit: 4,
    temperature: 400,
    time: 60 * 9,
  },
}
