import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import octicons from 'octicons'
import hash from 'object-hash'
import * as UI from '../../UI'
import * as MenuItem from '../MenuItem'
import * as Session from '../Session'
import Container from '../Container'
import Header from '../Header'
import Display from './Display'
import Edit from './Edit'
import * as utilities from './utilities'

export const UL = styled(UI.UL)`
  > li {
    user-select: none;

    > ${Display} {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  @media (min-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    vertical-align: top;
    flex-wrap: wrap;

    > li {
      margin: 30px;

      > ${Display} {
        margin: 0;
      }
    }
  }
`

export const UnbagButton = styled(UI.Button)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  height: 30px;
  padding: 5px 6px;
  font-size: 18px;
  line-height: 20px;
  color: white;

  > span {
    ~ span {
      margin-left: 4px;
    }
  }
`

export const MakeYourOwn = styled.div`
  position: relative;
  width: 350px;
  max-width: 100%;
  margin: 45px auto;
  padding: 30px;
  border: 2px dashed ${({ backgroundColor, theme }) => theme.colors[backgroundColor] || backgroundColor || theme.colors.primary};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.primary};
  background: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out, background 0.25s ease-in-out;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);
  }
`

export const FooterLink = styled(Link)`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 15px;
  border: 0;
  border-radius: 3px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: ${({ color, theme }) => theme.colors[color] || color || theme.colors.primary};
  background: transparent;
  cursor: pointer;

  > div {
    height: 45px;
  }
`

export const BagIcon = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  padding: 3px;
  color: ${({ color, theme }) => theme.colors[color] || color || `white`};
  background-color: ${({ color, theme }) => theme.colors[color] || color || `white`};

  &:before {
    content: '';
    position: absolute;
    top: 12px;
    bottom: 4px;
    left: 2px;
    right: 2px;
    border-radius: 2px;
    background: ${({ color }) => color ? `white` : `gray`};
    transition: background 0.25s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 6px;
    right: 6px;
    height: 12px;
    border: 4px solid ${({ color }) => color ? `white` : `gray`};
    border-radius: 2px;
    transition: border-color 0.25s ease-in-out;
  }

  > span {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 6px;
    text-align: center;
    font-size: 25px;
  }

  > div {
    position: absolute;
    left: 100%;
    top: 9px;
    margin-left: 3px;
    color: white;
    font-size: 30px;
  }
`

const List = ({ defaultMeals }) => {
  const [ editing, setEditing ] = React.useState({ index: -1, autoFocusId: ``, scrollToId: `` })

  React.useEffect(() => {
    if (editing.autoFocusId || editing.scrollToId) {              // reset these after render
      setEditing({ ...editing, autoFocusId: ``, scrollToId: `` }) // TODO find a better way?
    }
  }, [ editing ])

  return (
    <Container title='Airburger'>
      <Session.Context.Consumer>
        {({ session, setSession }) => {
          const user = session.user
          let meals = (user && user.meals) || defaultMeals
          const addNewMeal = meal => setSession({ ...session, user: { ...user, meals: [ ...meals, { ...meal } ] } })
          const baggedMeals = (user && user.baggedMeals) || []
          const bagMeal = meal => utilities.getTotalCost({ meal, toFixedString: false }) > 0 && setSession({ ...session, user: { ...user, baggedMeals: [ ...baggedMeals, { ...meal } ] } })
          const unbagMeal = meal => {
            let index = baggedMeals.length - 1

            while (index >= 0) {
              if (baggedMeals[index].id === meal.id) {
                setSession({ ...session, user: { ...user, baggedMeals: [ ...baggedMeals.slice(0, index), ...baggedMeals.slice(index + 1) ] } })
                break
              }
              index--
            }
          }

          const counts = {}
          baggedMeals.forEach(({ id }) => (counts[id] = (counts[id] || 0) + 1))

          let touchTimeout = 0

          return (
            <React.Fragment>
              <Header />

              <center>
                <UI.H2>
                  {user && user.firstName ? `Hey ${user.firstName}!` : `Hungry?`}
                </UI.H2>

                <UI.Text>
                  Tap to add a meal to your bag.<br />
                  Hold to change items.
                </UI.Text>
              </center>

              <UL>
                {meals.map((meal, index) => index === editing.index ? (
                  <li key={`meal_${meal.id}`}>
                    <Edit autoFocusId={editing.autoFocusId} scrollToId={editing.scrollToId} meal={meal} updateMeal={updates => {
                      meals = [ ...meals ]
                      meals[index] = { ...meal, ...updates }
                      setSession({ ...session, user: { ...user, meals } })
                    }}>
                      <div>
                        <span children={[
                          `${Math.ceil(utilities.getTotal({ meal, key: `nutrition.carbs` }))}g carbs`,
                          `${Math.ceil(utilities.getTotal({ meal, key: `nutrition.protein` }))}g protein`,
                          `${Math.ceil(utilities.getTotal({ meal, key: `nutrition.fat` }))}g fat`
                        ].join(', ')} />
                      </div>

                      <div>
                        <span>{utilities.getTotal({ meal, key: `nutrition.calories` })} Calories</span>
                        <span dangerouslySetInnerHTML={{ __html: octicons[`verified`].toSVG({ height: 20 }) }} />
                      </div>

                      <UI.Button backgroundColor='green' onClick={() => {
                        const menuItems = meal.menuItems && meal.menuItems.filter(menuItem => MenuItem.utilities.getTotalCost({ menuItem }) > 0)
                        let shouldSetSession = false

                        if (menuItems && menuItems.length !== meal.menuItems.length) {
                          meals = [ ...meals ]
                          meals[index] = { ...meal, menuItems }
                          meal = meals[index]
                          shouldSetSession = true
                        }

                        if (!utilities.getTotalCost({ meal, toFixedString: false })) {
                          meals = [ ...meals ]
                          meals.splice(index, 1)
                          shouldSetSession = true
                        }

                        let updated = false
                        const updatedMeals = meals.map(meal => {
                          const id = hash({ menuItems: meal.menuItems || [] })

                          if (id !== meal.id) {
                            updated = true
                            return { ...meal, id }
                          }

                          return meal
                        })

                        if (updated) {
                          meals = updatedMeals
                          shouldSetSession = true
                        }

                        if (shouldSetSession) {
                          setSession({ ...session, user: { ...user, meals } })
                        }

                        setEditing({ index: -1 })
                      }}>
                        <span dangerouslySetInnerHTML={{ __html: octicons[`smiley`].toSVG({ height: 20 }) }} />
                        <span>Done</span>
                      </UI.Button>
                    </Edit>
                  </li>
                ) : (
                  <li
                    key={`meal_${meal.id}`}
                    onClick={() => {
                      clearTimeout(touchTimeout)
                      bagMeal(meal)
                    }}
                    onTouchStart={() => {
                      touchTimeout = setTimeout(() => setEditing({ index, scrollToId: `info` }), 750)
                    }}
                    onTouchEnd={() => {
                      clearTimeout(touchTimeout)
                    }}
                    onTouchCancel={() => {
                      clearTimeout(touchTimeout)
                    }}
                  >
                    <Display meal={meal}>
                      {counts[meal.id] > 0 && (
                        <UnbagButton backgroundColor='red' onClick={(event) => {
                          unbagMeal(meal)
                          event.stopPropagation()
                          event.preventDefault()
                        }}>
                          <span dangerouslySetInnerHTML={{ __html: octicons[`x`].toSVG({ height: 20 }) }} />
                          <span>{counts[meal.id]} in bag</span>
                        </UnbagButton>
                      )}

                      <div onClick={event => {
                        setEditing({ index, scrollToId: `info` })
                        event.stopPropagation()
                        event.preventDefault()
                      }}>
                        <span>{utilities.getTotal({ meal, key: `nutrition.calories` })} Calories</span>
                        <span dangerouslySetInnerHTML={{ __html: octicons[`unverified`].toSVG({ height: 20 }) }} />
                      </div>
                    </Display>
                  </li>
                ))}

                <li>
                  <MakeYourOwn tabIndex={0} onClick={() => {
                    const newMealIndex = meals.length

                    if (!newMealIndex || utilities.getTotalCost({ meal: meals[newMealIndex - 1], toFixedString: false }) > 0) {
                      addNewMeal({ id: `${newMealIndex}-${new Date().getTime()}` })
                      setEditing({ index: newMealIndex, autoFocusId: `title`, scrollToId: `info` })
                    }
                  }}>
                    Make your own
                  </MakeYourOwn>
                </li>
              </UL>

              <UI.Footer>
                <FooterLink to='/orders' color='blue'>
                  <div dangerouslySetInnerHTML={{ __html: octicons[`history`].toSVG({ height: 50 }) }} />
                </FooterLink>

                <FooterLink to='/bag' color={baggedMeals.length ? `white` : `gray`} style={{ backgroundColor: baggedMeals.length ? `#309000` : `transparent` }} disabled={!baggedMeals.length}>
                  <BagIcon color={baggedMeals.length ? `green` : ``}>
                    {baggedMeals.length ? (
                      <span>{baggedMeals.length}</span>
                    ) : (
                      <span style={{ bottom: 5 }} dangerouslySetInnerHTML={{ __html: octicons[`smiley`].toSVG({ height: 20 }) }} />
                    )}

                    <div>»</div>
                  </BagIcon>
                </FooterLink>
              </UI.Footer>
            </React.Fragment>
          )
        }}
      </Session.Context.Consumer>
    </Container>
  )
}

List.propTypes = {
  defaultMeals: PropTypes.array.isRequired
}

List.defaultProps = {
  defaultMeals: [
    {
      id: `2f330204b1d17cfa8170d193dd3b6c14a063e862`,
      title: `Standard`,
      menuItems: [
        {
          key: `Burger`,
          ingredientKeys: [
            `BunTop`,
            `Lettuce`,
            `Onion`,
            `Tomato`,
            `Mayo`,
            `Cheese`,
            `PattyBeef`,
            `Mustard`,
            `Ketchup`,
            `BunBottom`
          ]
        },
        {
          key: `Fries`,
          ingredientKeys: [
            `FriesYukonGold`
          ]
        },
        {
          key: `Drink`,
          ingredientKeys: [
            `TeaSweet`
          ]
        }
      ]
    },
    {
      id: `59e248decc8f8aa09e71477c4e0115126ed41276`,
      title: `No Bun`,
      menuItems: [
        {
          key: `Burger`,
          ingredientKeys: [
            `Lettuce`,
            `Onion`,
            `Tomato`,
            `Mayo`,
            `Mustard`,
            `Ketchup`,
            `Cheese`,
            `PattyBeef`,
            `Lettuce`
          ]
        },
        {
          key: `Fries`,
          ingredientKeys: [
            `FriesSweetPotato`
          ]
        },
        {
          key: `Drink`,
          ingredientKeys: [
            `TeaSweet`
          ]
        }
      ]
    }
  ]
}

export default List
