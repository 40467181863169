import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import { mix } from 'polished'
import octicons from 'octicons'
import Form from './Form'
import * as utilities from '../utilities'

export const InputContainer = styled.span`
  position: relative;
  display: inline-block;
`

export const Input = styled.input.attrs({
  type: 'text'
})`
  display: inline-block;
  vertical-align: middle;
  width: 250px;
  height: 35px;
  margin: 0;
  padding: 9px 29px 9px 9px;
  border: 1px solid rgba(127, 127, 127, 0.125);
  border-radius: 1px;
  font-size: 12px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.9);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: inset 0 0 10px rgba(127, 127, 127, 0.125);
  transition: all 0.25s ease-in-out;

  @media (min-height: 900px) {
    font-size: 14px;
    padding: 8px 29px 8px 9px;
  }

  &:hover {
    border: 1px solid ${({ theme }) => mix(0.01, theme.colors.primary || `rgba(127, 127, 127, 0.9)`, `rgba(127, 127, 127, 0.9)`)};
  }

  &:focus,
  &:focus:hover {
    border: 1px solid ${({ theme }) => mix(0.375, theme.colors.primary || `rgba(127, 127, 127, 1)`, `rgba(127, 127, 127, 1)`)};
    background: white;
  }
`

export const Icon = styled.span`
  position: absolute;
  top: 50%;
  right: 7px;
  width: 15px;
  height: 15px;
  margin-top: -8px;
  color: ${({ theme }) => mix(0.25, theme.colors.primary || `rgba(63, 63, 63, 0.5)`, `rgba(63, 63, 63, 0.5)`)};
  pointer-events: none;
`

const Search = ({ onInput, history, location, children, ...props }) => {
  const query = utilities.queryStringToObject(location.search)
  let timeout = null

  return (
    <Form { ...props } onInput={(event, formData) => {
      if (onInput) {
        onInput(event, formData)
      }

      clearTimeout(timeout)
      timeout = setTimeout(() => {
        delete query.page

        history.push(`${location.pathname}?${
          utilities.objectToQueryString({
            ...query,
            ...formData
          })
        }`)
      }, 350)
    }}>
      <InputContainer>
        <Input
          type='text'
          name='title'
          placeholder='Search'
          icon='search'
          defaultValue={query.title || ''}
          autoFocus={Boolean(query.title)}
        />

        <Icon dangerouslySetInnerHTML={{ __html: octicons[`search`].toSVG() }} />
      </InputContainer>

      {children}
    </Form>
  )
}

Search.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onInput: PropTypes.func
}

export default withRouter(Search)
