module.exports = {
  title: `Cheese`,
  image: require('./Cheese.png'),
  height: 45,
  cost: 0.5,
  limit: 4,

  nutrition: {
    servingSize: `1 slice`,
    calories: 280,
    carbs: 27,
    fiber: 3,
    sugar: 9,
    fat: 20,
    saturatedFat: 5,
    protein: 30,
    sodium: 30,
    cholesterol: 30,
    calcium: 10,
    iron: 10,
  },

  collapsedStyle: {
    marginBottom: -50,
    transform: `translateY(-10%)`,
  },

  preparation: {
    limit: 4,
  },
}
