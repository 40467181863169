import React from 'react'
import styled from 'styled-components'
//import octicons from 'octicons'
import * as API from '../../API'
import * as UI from '../../UI'

export const Form = styled(UI.Form)`
  width: 300px;
  text-align: center;

  ${UI.Input} {
    width: 100%;
    margin-bottom: 15px;
  }
`

const Editor = ({ user }) => {
  const [ state, setState ] = React.useState({})

  return Boolean(user && user.id) && (
    <Form onSubmit={async (event, formData) => {
      //setState({ ...state, submitText: `Updating...` })

      try {
        //await crud.update(formData)
        setState({ ...state, submitText: `Disabled for now.` })
        setTimeout(() => setState({ ...state, submitText: `` }), 3000)
      } catch (error) {
        setState({ ...state, submitText: ``, error: API.utilities.getErrorMessage(error) || `Unknown error.` })
      }
    }}>
      <h1>Hello, {user.firstName}!</h1>
      {/*<UI.Input
        key='phone'
        name='phone'
        placeholder='Phone'
        value={user.phone || ``}
      />

      <UI.Input
        key='email'
        name='email'
        placeholder='Email'
        value={user.email || ``}
      />

      <UI.Input
        key='password'
        type='password'
        name='password'
        placeholder='Password'
      />

      <UI.Input
        key='firstName'
        name='firstName'
        placeholder='First Name'
        value={user.firstName || ``}
      />

      <UI.Input
        key='lastName'
        name='lastName'
        placeholder='Last Name'
        value={user.lastName || ``}
      />

      <UI.Button type='submit' backgroundColor='green' style={{ width: `100%` }} disabled={Boolean(state.submitText)}>
        <span dangerouslySetInnerHTML={{ __html: octicons[`smiley`].toSVG({ height: 20 }) }} />
        <span>{state.submitText || `Update`}</span>
      </UI.Button>

      <UI.Error>
        {state.error || ``}
      </UI.Error>

      <UI.Footer>
        <UI.Button
          backgroundColor='red'
          style={{ margin: 15 }}
          onClick={() => {
            window.localStorage.removeItem(`session`)
            API.api.removeHeaders()
            window.location.reload()
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: octicons[`sign-out`].toSVG({ height: 20 }) }} />
          <span>Logout</span>
        </UI.Button>
      </UI.Footer>
        */}
    </Form>
  )
}

export default Editor
