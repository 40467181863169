const queryStringToObject = queryString => {
  const queryObject = {}

  if (queryString) {
    queryString.replace(/^\?/, '').split('&').forEach(pair => {
      pair = pair.split('=')
      queryObject[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
    })
  }

  return queryObject;
}

export default queryStringToObject
