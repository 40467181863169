import getDeepObject from './getDeepObject'

const getFormData = (form, selector = `[name]`) => {
  const elements = Array.prototype.slice.call(form.querySelectorAll(selector))
  const formData = {}

  for (let element of elements) {
    if (element.name) {
      if (element.getAttribute('data-json')) {
        try {
          getDeepObject(
            element.name,
            JSON.parse(element.getAttribute('data-json')),
            formData
          )
        } catch (error) {
        }
      } else if (!element.value && element.innerText) {
        getDeepObject(element.name, element.innerText, formData)
      } else if (element.type === 'checkbox') {
        getDeepObject(element.name, element.checked ? element.value : '', formData)
      } else if (element.type === 'radio') {
        if (element.checked) {
          getDeepObject(element.name, element.value, formData)
        }
      } else {
        getDeepObject(element.name, element.value, formData)
      }
    }
  }

  return formData
}

export default getFormData
