import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mix } from 'polished'
import octicons from 'octicons'
import arrayMove from 'array-move'
import * as UI from '../../UI'
import * as Ingredient from '../Ingredient'
import * as MenuItem from '../MenuItem'
import * as utilities from './utilities'

export const MoveButton = styled.button`
  position: absolute;
  top: ${({ direction }) => ({ up: `-13px`, down: `8px` })[direction]};
  left: 0;
  border: 0;
  padding: 3px 5px;
  margin: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
`

let focusedTimeout = -1

const Edit = styled(({ autoFocusId, scrollToId, meal, updateMeal, children, ...props }) => {
  const time = new Date().getTime()
  const [ dollarAmount, centsAmount ] = utilities.getTotalCost({ meal, toFixedString: true }).split(`.`)
  const [ focused, setFocused ] = React.useState({})
  const doFocus = (focused, time) => {
    clearTimeout(focusedTimeout)
    setFocused(focused)

    if (time) {
      focusedTimeout = setTimeout(() => setFocused({}), time)
    }
  }

  const dragFrom = {}
  const dragTo = {}
  const autoScroll = ({ clientY }) => {
    const height = 45

    if (clientY < height) {
      document.documentElement.scrollTop--
    } else if ((window.innerHeight - clientY) < height) {
      document.documentElement.scrollTop++
    }
  }

  return (
    <div tabIndex={0} { ...props }>
      <UI.H3>
        <span>
          ${dollarAmount}<span>{centsAmount}</span>
        </span>

        <UI.Input
          placeholder='Name this meal'
          value={meal.title || ``}
          autoFocus={autoFocusId === `title`}
          onChange={event => updateMeal({ title: event.target.value })}
        />
      </UI.H3>

      <UI.UL>
        {meal.menuItems && meal.menuItems.map((mealMenuItem, mealMenuItemIndex) => {
          const menuItem = MenuItem[mealMenuItem.key]

          return menuItem && (
            <li key={`mealMenuItem_${mealMenuItemIndex}`} style={menuItem.style}>
              {menuItem.canAssemble && (
                <UI.Select
                  key={`ingredientAdder_${mealMenuItemIndex}_${time}`}
                  value=''
                  placeholder={`Add ${mealMenuItem.key} Ingredient`}
                  onChange={event => {
                    const ingredientKey = event.target.value

                    if (Ingredient[ingredientKey]) {
                      updateMeal({
                        menuItems: [
                          ...(meal.menuItems || []).slice(0, mealMenuItemIndex),
                          { ...mealMenuItem, ingredientKeys: [ ingredientKey, ...(mealMenuItem.ingredientKeys || []) ] },
                          ...(meal.menuItems || []).slice(mealMenuItemIndex + 1)
                        ]
                      })
                    }

                    doFocus({ mealMenuItemIndex, ingredientIndex: 0 }, 3000)
                  }}
                  onFocus={() => clearTimeout(focusedTimeout)}
                  onBlur={() => setFocused({})}
                >
                  <option value=''></option>

                  {[ ...menuItem.ingredientKeys ].sort((a, b) => Ingredient[a].title > Ingredient[b].title ? 1 : -1).map(ingredientKey => (
                    <option key={`menuItemIngredientKey_${mealMenuItemIndex}_${ingredientKey}`} value={ingredientKey}>
                      {Ingredient[ingredientKey].title}
                    </option>
                  ))}
                </UI.Select>
              )}

              {menuItem.ingredientTypes && (
                <UI.Select
                  placeholder={`Choose ${mealMenuItem.key} type`}
                  value={mealMenuItem.ingredientKeys[0]}
                  onChange={event => {
                    const ingredientKey = event.target.value

                    if (Ingredient[ingredientKey]) {
                      updateMeal({
                        menuItems: [
                          ...(meal.menuItems || []).slice(0, mealMenuItemIndex),
                          { ...mealMenuItem, ingredientKeys: [ ingredientKey ] },
                          ...(meal.menuItems || []).slice(mealMenuItemIndex + 1)
                        ]
                      })
                    }
                  }}
                >
                  {menuItem.ingredientTypes.map(ingredientKey => (
                    <option key={`menuItemIngredientType_${mealMenuItemIndex}_${ingredientKey}`} value={ingredientKey}>
                      {Ingredient[ingredientKey].title}
                    </option>
                  ))}
                </UI.Select>
              )}

              <UI.UL>
                {mealMenuItem.ingredientKeys.map((ingredientKey, ingredientIndex) => {
                  const ingredient = Ingredient[ingredientKey]

                  return ingredient && (
                    <li
                      key={`ingredient_${mealMenuItemIndex}_${ingredientIndex}_${ingredientKey}`}
                      style={{ zIndex: mealMenuItem.ingredientKeys.length - ingredientIndex }}
                      onClick={() => doFocus({ mealMenuItemIndex, ingredientIndex }, 3000)}
                      draggable={true}
                      onMouseDown={() => {
                        dragFrom.mealMenuItemIndex = mealMenuItemIndex
                        dragFrom.ingredientIndex = ingredientIndex
                      }}
                      onDragStart={event => {
                        if (dragFrom.mealMenuItemIndex !== mealMenuItemIndex || dragFrom.ingredientIndex !== ingredientIndex) {
                          dragFrom.mealMenuItemIndex = -1
                          dragFrom.ingredientIndex = -1
                          event.preventDefault()
                        } else {
                          document.addEventListener(`dragover`, autoScroll)
                        }
                      }}
                      onDragOver={event => {
                        if (dragFrom.mealMenuItemIndex === mealMenuItemIndex && dragFrom.ingredientIndex > -1) {
                          clearTimeout(focusedTimeout)
                          dragTo.mealMenuItemIndex = mealMenuItemIndex
                          dragTo.ingredientIndex = ingredientIndex
                        }
                      }}
                      onDragEnd={event => {
                        if (
                          dragFrom.mealMenuItemIndex === mealMenuItemIndex
                          && dragFrom.ingredientIndex > -1
                          && dragFrom.ingredientIndex !== dragTo.ingredientIndex
                          && dragTo.ingredientIndex > -1
                          && dragTo.ingredientIndex < mealMenuItem.ingredientKeys.length
                        ) {
                          const ingredientKeys = arrayMove(mealMenuItem.ingredientKeys, dragFrom.ingredientIndex, dragTo.ingredientIndex)

                          updateMeal({
                            menuItems: [
                              ...(meal.menuItems || []).slice(0, mealMenuItemIndex),
                              { ...mealMenuItem, ingredientKeys },
                              ...(meal.menuItems || []).slice(mealMenuItemIndex + 1)
                            ]
                          })

                          doFocus({ mealMenuItemIndex, ingredientIndex: dragTo.ingredientIndex }, 3000)
                        }

                        dragFrom.mealMenuItemIndex = -1
                        dragFrom.ingredientIndex = -1
                        dragTo.mealMenuItemIndex = -1
                        dragTo.ingredientIndex = -1
                        document.removeEventListener(`dragover`, autoScroll)
                      }}
                    >
                      <img
                        alt={ingredient.title}
                        src={ingredient.image}
                        style={{ maxHeight: ingredient.height }}
                      />

                      {focused.mealMenuItemIndex === mealMenuItemIndex && focused.ingredientIndex === ingredientIndex && (
                        <figure>
                          <figcaption>
                            <UI.H4>{ingredient.title}</UI.H4>
        
                            <div>
                              <div>Calories: <b>{MenuItem.utilities.getTotal({ menuItem: { ingredientKeys: [ ingredientKey ] }, key: `nutrition.calories` })}</b></div>
                              <div>Carbs: <b>{MenuItem.utilities.getTotal({ menuItem: { ingredientKeys: [ ingredientKey ] }, key: `nutrition.carbs` })}g</b></div>
                              <div>Protein: <b>{MenuItem.utilities.getTotal({ menuItem: { ingredientKeys: [ ingredientKey ] }, key: `nutrition.protein` })}g</b></div>
                              <div>Fat: <b>{MenuItem.utilities.getTotal({ menuItem: { ingredientKeys: [ ingredientKey ] }, key: `nutrition.fat` })}g</b></div>
        
                              <div>
                                <UI.Button
                                  backgroundColor='red'
                                  onClick={event => {
                                    updateMeal({
                                      menuItems: [
                                        ...(meal.menuItems || []).slice(0, mealMenuItemIndex),
                                        {
                                          ...mealMenuItem,
                                          ingredientKeys: [
                                            ...(mealMenuItem.ingredientKeys || []).slice(0, ingredientIndex),
                                            ...(mealMenuItem.ingredientKeys || []).slice(ingredientIndex + 1)
                                          ]
                                        },
                                        ...(meal.menuItems || []).slice(mealMenuItemIndex + 1)
                                      ]
                                    })

                                    doFocus({})
                                    event.stopPropagation()
                                    event.preventDefault()
                                  }}
                                >
                                  <span dangerouslySetInnerHTML={{ __html: octicons[`x`].toSVG({ height: 15 }) }} />
                                  <span>Remove</span>
                                </UI.Button>
                              </div>
                            </div>

                            {ingredientIndex > 0 && (
                              <MoveButton direction='up' onClick={event => {
                                const ingredientKeys = arrayMove(mealMenuItem.ingredientKeys, ingredientIndex, ingredientIndex - 1)
      
                                updateMeal({
                                  menuItems: [
                                    ...(meal.menuItems || []).slice(0, mealMenuItemIndex),
                                    { ...mealMenuItem, ingredientKeys },
                                    ...(meal.menuItems || []).slice(mealMenuItemIndex + 1)
                                  ]
                                })
      
                                doFocus({ mealMenuItemIndex, ingredientIndex: ingredientIndex - 1 }, 3000)
                                event.stopPropagation()
                                event.preventDefault()
                              }}>
                                ↑
                              </MoveButton>
                            )}

                            {ingredientIndex < (mealMenuItem.ingredientKeys.length - 1) && (
                              <MoveButton direction='down' onClick={event => {
                                const ingredientKeys = arrayMove(mealMenuItem.ingredientKeys, ingredientIndex, ingredientIndex + 1)
      
                                updateMeal({
                                  menuItems: [
                                    ...(meal.menuItems || []).slice(0, mealMenuItemIndex),
                                    { ...mealMenuItem, ingredientKeys },
                                    ...(meal.menuItems || []).slice(mealMenuItemIndex + 1)
                                  ]
                                })
      
                                doFocus({ mealMenuItemIndex, ingredientIndex: ingredientIndex + 1 }, 3000)
                                event.stopPropagation()
                                event.preventDefault()
                              }}>
                                ↓
                              </MoveButton>
                            )}
                          </figcaption>
                        </figure>
                      )}
                    </li>
                  )
                })}
              </UI.UL>

              {typeof focused.mealMenuItemIndex === `undefined` && (
                <figure>
                  <figcaption>
                    <UI.H4>{mealMenuItem.title || mealMenuItem.key}</UI.H4>

                    <div>
                      <div>Calories: <b>{MenuItem.utilities.getTotal({ menuItem: mealMenuItem, key: `nutrition.calories` })}</b></div>
                      <div>Carbs: <b>{MenuItem.utilities.getTotal({ menuItem: mealMenuItem, key: `nutrition.carbs` })}g</b></div>
                      <div>Protein: <b>{MenuItem.utilities.getTotal({ menuItem: mealMenuItem, key: `nutrition.protein` })}g</b></div>
                      <div>Fat: <b>{MenuItem.utilities.getTotal({ menuItem: mealMenuItem, key: `nutrition.fat` })}g</b></div>

                      <div>
                        <UI.Button
                          backgroundColor='red'
                          onClick={event => {
                            const menuItems = (meal.menuItems || []).concat([])
                            menuItems.splice(mealMenuItemIndex, 1)
                            updateMeal({ menuItems })

                            doFocus({})
                            event.stopPropagation()
                            event.preventDefault()
                          }}
                        >
                          <span dangerouslySetInnerHTML={{ __html: octicons[`x`].toSVG({ height: 15 }) }} />
                          <span>Remove</span>
                        </UI.Button>
                      </div>
                    </div>
                  </figcaption>
                </figure>
              )}
            </li>
          )
        })}
      </UI.UL>

      <div>
        {children}

        <UI.Select
          key={`menuItemAdder_${time}`}
          value=''
          placeholder={(
            <UI.Button>
              <span dangerouslySetInnerHTML={{ __html: octicons[`plus`].toSVG({ height: 15 }) }} />
              <span>Add Item</span>
            </UI.Button>
          )}
          onChange={event => {
            const key = event.target.value
            const menuItem = MenuItem[key]

            if (menuItem) {
              updateMeal({
                menuItems: (meal.menuItems || []).concat({
                  key,
                  ingredientKeys: menuItem.ingredientTypes ? [ menuItem.ingredientTypes[0] ] : [].concat(menuItem.ingredientKeys)
                })
              })
            }
          }}
        >
          <option value=''></option>
          <option value='Burger'>Burger</option>
          <option value='Fries'>Fries</option>
          <option value='Drink'>Drink</option>
        </UI.Select>
      </div>

      <span ref={ref => ref && scrollToId === `info` && ref.scrollIntoView({ behavior: `smooth`, block: `nearest` })} />
    </div>
  )
})`
  display: block;
  width: 350px;
  max-width: 100%;
  margin: 45px auto;
  border-radius: 3px;
  background: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.25s ease-in-out, background 0.25s ease-in-out;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);
  }

  > ${UI.H3} {
    height: 55px;
    padding: 15px 10px 15px 15px;
    margin: 0 0 15px;
    border-radius: 3px;
    text-align: left;
    background: ${({ theme }) => theme.colors.primary};
    color: white;

    > span:first-child {
      float: right;
      font-size: 35px;
      line-height: 22px;

      > span {
        display: inline-block;
        vertical-align: top;
        font-size: 20px;
        line-height: 13px;
        margin-left: 2px;
        border-bottom: 1px solid white;
        padding-bottom: 3px;
      }
    }

    > ${UI.Input} {
      width: 200px;
      margin: -15px;
      top: -8px;
      left: 11px;

      > input {
        padding: 18px 3px 5px;
        font-size: 25px;
        color: white;
        background: transparent;

        &:focus {
          border-bottom-color: white;
        }

        ~ span {
          top: 6px;
          color: white;
        }
      }
    }
  }

  > ${UI.UL} {
    position: relative;
    width: 100%;
    padding: 0 10px;

    > li {
      position: relative;
      margin: 15px 0 45px 0;
      min-height: 100px;

      > ${UI.Select} {
        width: 60%;
        margin: 0 0 15px;
      }

      > ${UI.UL} {
        > li {
          position: relative;
          display: block;
          margin: 0 auto;
          padding: 0 40% 0 0;

          > img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            max-height: 100px;
          }
        }
      }

      figure {
        position: absolute;
        z-index: 100;
        top: 0;
        bottom: 0;
        left: 65%;
        right: 0;
        min-height: 50px;
        text-align: right;
        margin: 0;
        padding: 0;
        border: 0;
        border-left: 1px solid ${({ theme }) => mix(0.5, theme.colors.primary || `rgba(127, 127, 127, 1)`, `rgba(127, 127, 127, 1)`)};
        font-size: 15px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 100%;
          width: 9px;
          margin-right: 1px;
          border-top: 1px solid ${({ theme }) => mix(0.5, theme.colors.primary || `rgba(127, 127, 127, 1)`, `rgba(127, 127, 127, 1)`)};
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 100%;
          width: 9px;
          margin-right: 1px;
          border-bottom: 1px solid ${({ theme }) => mix(0.5, theme.colors.primary || `rgba(127, 127, 127, 1)`, `rgba(127, 127, 127, 1)`)};
        }

        > figcaption {
          position: relative;
          margin: 14px 0 0;
          padding: 5px;
          border: 0;
          border-bottom: 1px solid ${({ theme }) => mix(0.5, theme.colors.primary || `rgba(127, 127, 127, 1)`, `rgba(127, 127, 127, 1)`)};
          white-space: nowrap;

          > ${UI.H4} {
            font-size: 20px;
          }

          > div {
            position: absolute;
            top: 100%;
            right: 0;
            padding: 5px;

            > div {
              > ${UI.Button} {
                height: 25px;
                padding: 4px 5px;
                margin-top: 5px;
                font-size: 13px;
                line-height: 15px;

                > span {
                  height: 15px;

                  ~ span {
                    margin-left: 4px;
                    margin-right: 1px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  > div {
    position: relative;
    min-height: 45px;
    padding: 10px;

    > div {
      margin: 5px 0;
      font-size: 18px;
      line-height: 20px;
      text-align: center;

      > span {
        display: inline-block;
        vertical-align: middle;
        height: 20px;
        margin-left: 5px;
      }
    }

    > ${UI.Button} {
      float: right;
      clear: right;
      width: 40%;
      margin-top: 30px;
    }

    > ${UI.Select} {
      width: 57%;
      height: 45px;
      margin-top: 30px;
    }
  }
`

Edit.propTypes = {
  autoFocusId: PropTypes.oneOf([ ``, `title` ]),
  scrollToId: PropTypes.oneOf([ ``, `info` ]),
  meal: PropTypes.object.isRequired,
  updateMeal: PropTypes.func.isRequired,
}

export default Edit
