module.exports = {
  title: `Onion`,
  image: require('./Onion.png'),
  height: 42,
  limit: 4,

  nutrition: {
    calories: 40,
    carbs: 9,
    fiber: 1,
    sugar: 4,
    protein: 1,
    sodium: 4,
    potassium: 146,
    vitaminC: 7,
    calcium: 23,
  },

  collapsedStyle: {
    marginTop: -30,
    maxWidth: `75%`,
  },
  
  preparation: {
    limit: 4,
  },
}
