import React from 'react'
import styled from 'styled-components'
import { mix } from 'polished'

const Combo = ({
  className,
  name,
  placeholder,
  value,
  inputValue,
  onChange,
  children,
  ...props
}) => {
  React.Children.forEach(children, option => {
    if (option.props.value === value && typeof option.props.children === `string`) {
      inputValue = option.props.children
    }
  })

  const [ state, setState ] = React.useState({ value, inputValue })

  React.useEffect(() => setState({ value, inputValue }), [ value, inputValue ])

  if (typeof children === `function`) {
    children = children(state)
  }

  return (
    <span className={className} data-placeholder={placeholder}>
      <input
        { ...props }
        type='text'
        value={state.inputValue}
        onChange={event => setState({ value, inputValue: event.target.value })}
      />

      {name && (
        <input
          type='hidden'
          name={name}
          value={state.value}
        />
      )}

      <ul>
        {React.Children.map(children, option => React.cloneElement(option, {
          onMouseDown: () => {
            const { value } = option.props
            const nextState = { value }

            if (typeof option.props.children === `string`) {
              nextState.inputValue = option.props.children
            }

            setState(nextState)

            if (onChange) {
              onChange({ target: { name, value } }, setState)
            }
          }
        }))}
      </ul>

      <span>{placeholder}</span>
      <b>▼</b>
    </span>
  )
}

// TODO figure out a way to reuse this css
export default styled(Combo).attrs({
  value: ``,
  inputValue: ``
})`
  position: relative;
  display: inline-block;
  vertical-align: middle;

  > input {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 45px;
    margin: 0;
    padding: 22px 22px 5px 3px;
    border: 1px solid transparent;
    border-bottom-color: ${({ theme }) => mix(0.1, theme.colors.primary || `rgba(223, 223, 223, 1)`, `rgba(223, 223, 223, 1)`)};
    border-radius: 1px;
    background: transparent;
    color: rgba(0, 0, 0, 0.9);
    font-size: 18px;
    line-height: 20px;
    text-align: inherit;
    appearance: none;
    transition: all 0.25s ease-in-out;

    @media (min-height: 900px) {
      height: 40px;
      padding: 18px 3px 3px;
      font-size: 17px;
      line-height: 19px;
    }

    &:hover {
      border-bottom-color: ${({ theme }) => mix(0.1, theme.colors.primary || `rgba(223, 223, 223, 0.75)`, `rgba(223, 223, 223, 0.75)`)};
      background: white;
    }

    &:focus,
    &:focus:hover {
      border-bottom-color: ${({ theme }) => mix(0.5, theme.colors.primary || `rgba(127, 127, 127, 1)`, `rgba(127, 127, 127, 1)`)};
      background: white;
    }

    &:hover,
    &:focus,
    &:focus:hover {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    }

    ~ ul {
      display: none;
      list-style: none;
      margin: 0;
      padding: 3px 0;
      background: white;
      box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.05);
      position: absolute;
      z-index: 10;
      top: 100%;
      left: 0;
      right: 0;
      max-height: 250px;
      overflow: auto;

      > * {
        padding: 10px 15px;
        font-size: 12px;
        line-height: 1;
        color: ${({ theme }) => mix(0.125, theme.colors.primary, `#666666`)};
        background: white;
        cursor: pointer;

        @media (min-height: 900px) {
          font-size: 14px;
        }

        &:hover {
          color: inherit;
          background: ${({ theme }) => mix(0.125, theme.colors.primary, `#f6f6f6`)};
          transition: color 0.25s ease-in-out, background 0.25s ease-in-out;
        }
      }
    }

    ~ span {
      position: absolute;
      width: 100%;
      top: 3px;
      left: 3px;
      font-size: 9px;
      line-height: 1;
      white-space: nowrap;
      color: gray;
      text-transform: uppercase;
      pointer-events: none;
      transition: all 0.25s ease-in-out;

      @media (min-height: 900px) {
        font-size: 11px;
      }
    }

    &:focus {
      ~ ul {
        display: block;
      }

      ~ span {
        color: ${({ theme }) => mix(0.5, theme.colors.primary || `rgba(127, 127, 127, 1)`, `rgba(127, 127, 127, 1)`)};
        text-shadow: 0 0 ${({ theme }) => mix(0.5, theme.colors.primary || `rgba(127, 127, 127, 1)`, `rgba(127, 127, 127, 1)`)};
      }
    }
  }

  b {
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    padding: 0 5px;
    font-size: 10px;
    font-weight: normal;
    line-height: 45px;
    background-color: white;
    pointer-events: none;
    opacity: 0.25;
    transition: all 0.25s ease-in-out;

    @media (min-height: 900px) {
      font-size: 12px;
      line-height: 40px;
    }
  }

  &:hover {
    b {
      opacity: 0.5;
    }
  }
`
