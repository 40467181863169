import React from 'react'
import styled from 'styled-components'

const Logo = (props) => (
  <span { ...props }>
    <span>Air</span><span>Burger</span>
  </span>
)

export default styled(Logo)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: -3px;

  > span:first-child {
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 3px;
  }
`
