import * as MenuItem from '../MenuItem'

export const getTotal = ({ meal, key, initialValue = 0 }) => {
  let total = initialValue

  if (Array.isArray(meal.menuItems)) {
    for (let menuItem of meal.menuItems) {
      total += MenuItem.utilities.getTotal({ menuItem, key }) || 0
    }
  }

  return total
}

export const getTotalCost = ({ meal, count = 1, toFixedString }) => {
  const totalCost = getTotal({ meal, key: `cost` }) * count

  if (typeof toFixedString === `undefined`) {
    toFixedString = Math.floor(totalCost) !== totalCost
  }

  return toFixedString ? totalCost.toFixed(2) : totalCost
}
