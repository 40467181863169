module.exports = {
  ingredientKeys: [
    `FriesYukonGold`,
    `FriesSweetPotato`
  ],

  ingredientTypes: [
    `FriesYukonGold`,
    `FriesSweetPotato`
  ]
}
