import React from 'react'
import * as API from '../../API'
import * as UI from '../../UI'
import Container from '../Container'
import { Form } from './Login'

const ConfirmToken = () => {
  const token = window.location.hash && window.location.hash.replace(/^#/, ``)
  const Authorization = token && `Bearer ${token}`

  if (token) {
    API.api.setHeaders({ Authorization }, `userId:unknown`)
    window.location.hash = ``
  }

  return (
    <Container title='Set Session Token' session={{}}>
      <API.Fetch postEndpoints={{ session: [`users/session`] }}>
        {fetchProps => {
          let children = (
            <UI.Text>
              Confirming token...
            </UI.Text>
          )

          if (!fetchProps.fetching) {
            const { session, token } = (fetchProps && fetchProps.data && fetchProps.data.session && fetchProps.data.session.data) || {}
            const Authorization = token && `Bearer ${token}`
            
            if (Authorization && session.user.id) {
              API.api.setHeaders({ Authorization }, `userId:${session.user.id}`)
              setTimeout(() => window.location.href = `/`, 3000)

              children = (
                <React.Fragment>
                  <UI.Text>
                    Token confirmed!
                  </UI.Text>

                  <UI.Text>
                    Taking you home...
                  </UI.Text>
                </React.Fragment>
              )
            } else {
              children = (
                <UI.Text>
                  Bad token!
                </UI.Text>
              )
            }
          }

          return (
            <UI.Modal>
              <Form>
                {children}
              </Form>
            </UI.Modal>
          )
        }}
      </API.Fetch>
    </Container>
  )
}

export default ConfirmToken
