import styled from 'styled-components'

const Header = styled.header.attrs({
  role: `banner`
})`
  z-index: 1001;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 15px;
  background: white;
  box-shadow: 0 -3px 6px 6px rgba(0, 0, 0, 0.05);

  body.scrolledUp & {
    position: fixed;
  }
`

export default Header
