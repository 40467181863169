import React from 'react'
import styled from 'styled-components'
import { mix } from 'polished'
import octicons from 'octicons'

const Avatar = (props) => props.src ? (
  <img alt='Avatar' { ...props } />
) : (
  <span { ...props } dangerouslySetInnerHTML={{ __html: octicons[`person`].toSVG({ width: 26, height: 26 }) }} />
)

export default styled(Avatar).attrs({
  id: `Avatar`
})`
  width: 40px;
  height: 40px;
  padding: 7px;
  border-radius: 50%;
  background-color: ${({ theme }) => mix(0.1, theme.colors.primary, `rgba(255, 255, 255, 0.5)`)};
`
