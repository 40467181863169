module.exports = {
  title: `Ketchup`,
  image: require('./Ketchup.png'),
  height: 35,
  limit: 4,

  nutrition: {
    servingSize: `0.5 tbsp`,
    calories: 50,
    carbs: 13,
    sugar: 10,
    protein: 1,
    sodium: 450,
    potassium: 140,
    vitaminA: 13,
    vitaminC: 2,
    calcium: 7,
  },

  collapsedStyle: {
    maxWidth: `60%`,
  },

  preparation: {
    limit: 4,
  },
}
