import styled from 'styled-components'

const Footer = styled.footer`
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 75px;
  padding: 0;
  background: white;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
`

export default Footer
