import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import * as Session from './Session'

const Container = styled(({ title, theme, session, fetchSession, ...props }) => {
  React.useEffect(() => {
    document.title = title
  }, [ title ])

  return (
    <ThemeProvider theme={theme}>
      <Session.Provider session={session} fetchSession={fetchSession}>
        <main { ...props } />
      </Session.Provider>
    </ThemeProvider>
  )
})`
  position: relative;
  padding: 90px 15px;
  color: ${({ theme }) => theme.colors.text};
  max-width: 100%;
  min-height: calc(100vh);
  overflow-x: hidden;
`

Container.propTypes = {
  title: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  session: PropTypes.object,
  fetchSession: PropTypes.bool
}

Container.defaultProps = {
  theme: {
    colors: {
      primary: `#4070e0`,
      text: `#444444`,
      red: `#d02000`,
      green: `#309000`,
      blue: `#4070e0`,
    }
  }
}

export default Container
