const getDeepObject = (keys, value, deepObject = {}) => {
  let current = deepObject
  let key

  keys = keys.split(`.`)

  while (keys.length) {
    key = keys.shift()
    current = (current[key] = keys.length ? (current[key] || {}) : value)
  }

  return deepObject
}

export default getDeepObject
