const Burger = require('./Burger')
const Drink = require('./Drink')
const Fries = require('./Fries')
const utilities = require('./utilities')

module.exports = {
  Burger,
  Drink,
  Fries,
  utilities
}
