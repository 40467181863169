module.exports = {
  title: `Yukon Gold`,
  image: require('./FriesYukonGold.png'),
  height: 140,
  cost: 3,
  limit: 1,

  nutrition: {
    calories: 260,
    carbs: 36,
    fiber: 6,
    fat: 8,
    protein: 4,
    sodium: 360,
    potassium: 500,
    vitaminA: 12,
    vitaminC: 18,
    iron: 30,
  },
  
  preparation: {
    limit: 1,
    temperature: 400,
    time: 60 * 9,
  },
}
