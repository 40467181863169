import React from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'
import * as Meal from './Meal'
import * as Order from './Order'
import * as Session from './Session'

const App = () => (
  <HashRouter>
    <Switch>
      <Route exact path='/'>
        <Meal.List />
      </Route>

      <Route exact path='/bag'>
        <Meal.Bag />
      </Route>

      <Route exact path='/orders/:orderId?'>
        <Order.History />
      </Route>

      <Route exact path='/confirm-session-token'>
        <Session.ConfirmToken />
      </Route>
    </Switch>
  </HashRouter>
)

export default App
