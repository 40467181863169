module.exports = {
  ingredientKeys: [
    `BunTop`,
    `Lettuce`,
    `Onion`,
    `Tomato`,
    `Mayo`,
    `Cheese`,
    `PattyBeef`,
    `Mustard`,
    `Ketchup`,
    `BunBottom`,
  ],

  canAssemble: true
}
