import React from 'react'
import styled from 'styled-components'
import octicons from 'octicons'
import * as API from '../../API'
import * as UI from '../../UI'

const isPhone = str => /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(str)
const isEmail = str => /.+@.+\..+/.test(str)

export const Form = styled(UI.Form)`
  width: 300px;
  text-align: center;

  ${UI.Input} {
    width: 100%;
    margin-bottom: 15px;
  }
`

export const ForgotPassword = styled.a`
  display: inline-block;
  color: gray;
  font-size: 11px;
  text-align: center;
  margin-top: 15px;
`

const Login = () => {
  const [ state, setState ] = React.useState({})

  if (state.joining) {
    return (
      <Form onSubmit={async (event, formData) => {
        setState({ ...state, submitText: `Joining...` })

        try {
          await API.api.post(`users`, formData)
          setState({ ...state, submitText: `Logging in...` })

          const { data } = await API.api.post(`users/login`, { phone: formData.phone, email: formData.email, password: formData.password })
          const { session, token } = data

          API.api.setHeaders({ Authorization: `Bearer ${token}` }, `userId:${session.user.id}`)
          window.location.reload()
        } catch (error) {
          setState({ ...state, submitText: ``, joinError: API.utilities.getErrorMessage(error) || `Unknown error.` })
        }
      }}>
        <UI.Input
          key='phone'
          name='phone'
          placeholder='Phone'
          value={state.phone || ``}
          onInput={event => setState({ ...state, phone: event.target.value })}
        />

        <UI.Input
          key='email'
          name='email'
          placeholder='Email'
          value={state.email || ``}
          onInput={event => setState({ ...state, email: event.target.value })}
        />

        <UI.Input
          key='password'
          type='password'
          name='password'
          placeholder='Password'
        />

        <UI.Input
          key='firstName'
          name='firstName'
          placeholder='First Name'
        />

        <UI.Input
          key='lastName'
          name='lastName'
          placeholder='Last Name'
        />

        <UI.Button type='submit' backgroundColor='green' style={{ width: `100%` }} disabled={Boolean(state.submitText)}>
          <span dangerouslySetInnerHTML={{ __html: octicons[`smiley`].toSVG({ height: 20 }) }} />
          <span>{state.submitText || `Join`}</span>
        </UI.Button>

        <UI.Error>
          {state.joinError || ``}
        </UI.Error>
      </Form>
    )
  }

  if (state.forgotPasswordMessage) {
    return (
      <Form>
        <UI.Text>{state.forgotPasswordMessage}</UI.Text>
      </Form>
    )
  }

  if (state.forgotPassword) {
    return (
      <Form onSubmit={async (event, formData) => {
        setState({ ...state, submitText: `Submitting...` })
  
        try {
          const { data } = await API.api.post(`users/forgot-password`, formData)
          const { message } = data

          if (message) {
            setState({ ...state, forgotPasswordMessage: message })
          }
        } catch (error) {
          setState({ ...state, submitText: ``, forgotPasswordError: API.utilities.getErrorMessage(error) || `Unknown error.` })
        }
      }}>
        <UI.Input
          key='phoneOrEmail'
          name={(state.phone && `phone`) || `email`}
          placeholder={(state.phone && `Phone`) || (state.email && `Email`) || `Phone or Email`}
          autoFocus={true}
          onInput={event => {
            const { value } = event.target
  
            if (isPhone(value)) {
              setState({ phone: value, forgotPassword: true })
            } else if (isEmail(value)) {
              setState({ email: value, forgotPassword: true })
            } else {
              setState({ forgotPassword: true})
            }
          }}
        />
  
        <UI.Button
          type='submit'
          style={{ width: `100%` }}
          disabled={Boolean(state.submitText)}
        >
          <span dangerouslySetInnerHTML={{ __html: octicons[`smiley`].toSVG({ height: 20 }) }} />
          <span>{state.submitText || `Submit`}</span>
        </UI.Button>
  
        <UI.Error>
          {state.forgotPasswordError || ``}
        </UI.Error>
      </Form>
    )
  }

  return (
    <Form onSubmit={async (event, formData) => {
      setState({ ...state, submitText: `Logging in...` })

      try {
        const { data } = await API.api.post(`users/login`, formData)
        const { session, token } = data

        API.api.setHeaders({ Authorization: `Bearer ${token}` }, `userId:${session.user.id}`)
        window.location.reload()
      } catch (error) {
        setState({ ...state, submitText: ``, loginError: API.utilities.getErrorMessage(error) || `Unknown error.` })
      }
    }}>
      <UI.Input
        key='phoneOrEmail'
        name={(state.phone && `phone`) || `email`}
        placeholder={(state.phone && `Phone`) || (state.email && `Email`) || `Phone or Email`}
        autoFocus={true}
        onInput={event => {
          const { value } = event.target

          if (isPhone(value)) {
            setState({ phone: value })
          } else if (isEmail(value)) {
            setState({ email: value })
          } else {
            setState({})
          }
        }}
      />

      <UI.Input
        key='password'
        type='password'
        name='password'
        placeholder='Password'
      />

      <UI.Button
        backgroundColor='green'
        style={state.submitText ? { display: `none` } : { width: `49%`, marginRight: `2%` }}
        disabled={Boolean(state.submitText)}
        onClick={() => setState({ ...state, joining: true })}
      >
        <span dangerouslySetInnerHTML={{ __html: octicons[`smiley`].toSVG({ height: 20 }) }} />
        <span>Join</span>
      </UI.Button>

      <UI.Button
        type='submit'
        style={state.submitText ? { width: `100%` } : { width: `49%` }}
        disabled={Boolean(state.submitText)}
      >
        <span dangerouslySetInnerHTML={{ __html: octicons[`cloud-upload`].toSVG({ height: 20 }) }} />
        <span>{state.submitText || `Login`}</span>
      </UI.Button>

      <UI.Error>
        {state.loginError || ``}
      </UI.Error>

      <ForgotPassword onClick={() => setState({ ...state, forgotPassword: true })}>
        Forgot password?
      </ForgotPassword>
    </Form>
  )
}

export default Login
