module.exports = {
  BunBottom: require('./BunBottom'),
  BunTop: require('./BunTop'),
  Cheese: require('./Cheese'),
  FriesSweetPotato: require('./FriesSweetPotato'),
  FriesYukonGold: require('./FriesYukonGold'),
  Ketchup: require('./Ketchup'),
  Lettuce: require('./Lettuce'),
  Mayo: require('./Mayo'),
  Mustard: require('./Mustard'),
  Onion: require('./Onion'),
  PattyBeef: require('./PattyBeef'),
  TeaSweet: require('./TeaSweet'),
  TeaUnsweet: require('./TeaUnsweet'),
  Tomato: require('./Tomato'),
}
