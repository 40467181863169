import React from 'react'
import * as UI from '../../UI'
import Avatar from './Avatar'
import Context from './Context'
import Login from './Login'
import Logo from '../Logo'
import * as User from '../User'

const Menu = () => {
  const [ visible, setVisible ] = React.useState(false)

  return (
    <React.Fragment>
      <Avatar onClick={() => setVisible(!visible)} />

      {visible && (
        <UI.Modal>
          <Logo />

          <Context.Consumer>
            {({ session }) => session.user && session.user.id ? (
              <User.Editor user={session.user} />
            ) : (
              <Login />
            )}
          </Context.Consumer>

          <UI.Modal.CloseButton onClick={() => setVisible(false)} />
        </UI.Modal>
      )}
    </React.Fragment>
  )
}

export default Menu
