module.exports = {
  title: `Unsweet Tea`,
  image: require('./TeaUnsweet.png'),
  height: 160,
  cost: 1,
  limit: 1,

  nutrition: {
    calories: 5,
  },

  preparation: {
    limit: 1,
  },
}
