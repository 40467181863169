import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import api from './api'
import * as UI from '../UI'

class Fetch extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      fetching: true,
      errors: null,
      data: {}
    }
  }

  componentDidMount() {
    this.prevLocation = this.props.location
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    if (this.props.autoRefetch && this.prevLocation !== this.props.location) {
      this.prevLocation = this.props.location
      this.fetch()
    }
  }

  componentWillUnmount() {
    this.promiseMap = null
    this.promises = null
  }

  fetch(keys) {
    const { handlers, children, ...props } = this.props
    const promiseMap = {}
    const promises = []
    const nextData = {}
    let nextErrors = null

    const handleData = key => data => {
      nextData[key] = data
    }

    const handleError = key => error => {
      nextErrors = nextErrors || {}
      nextErrors[key] = error
    }

    if (!keys) {
      keys = Object.keys(props)
    }

    for (let propKey of keys) {
      const handler = handlers[propKey]
      let prop = props[propKey]

      if (handler && prop) {
        if (typeof prop !== `object`) {
          prop = { [prop.split(`?`)[0]]: prop }
        }

        for (let key in prop) {
          const promise = handler(key, prop[key])
            .then(handleData(key))
            .catch(handleError(key))

          // TODO cancel active request for this key?
          promiseMap[key] = promise
          promises.push(promise)
        }
      }
    }

    this.promiseMap = promiseMap
    this.promises = promises

    if (!promises.length) {
      return
    }

    this.setState({
      fetching: true
    })

    Promise.all(promises).then(() => {
      if (this.promises === promises) {
        this.setState({
          fetching: false,
          errors: nextErrors,
          data: nextData
        })
      }
    })
  }

  render() {
    const fetch = keys => this.fetch(keys)
    const { fetching, errors, data } = this.state

    const fetchComponents = {
      fetching: fetching && (
        <UI.Spinner title={Object.keys(this.promiseMap || {}).join(`, `)} />
      ),

      errors: errors && (
        <UI.Error>
          {Object.keys(errors).map(key => (
            <div key={`error_${key}`}>{`${key}: ${errors[key]}`}</div>
          ))}
        </UI.Error>
      )
    }

    return (this.props.autoStatusUpdate && (fetchComponents.fetching || fetchComponents.errors))
      || this.props.children({ fetching, fetch, fetchComponents, errors, data })
  }
}

Fetch.propTypes = {
  location: PropTypes.object.isRequired,
  handlers: PropTypes.object.isRequired,
  autoRefetch: PropTypes.bool,
  autoStatusUpdate: PropTypes.bool,
  childrenOnly: PropTypes.bool
}

Fetch.defaultProps = {
  handlers: {
    getEndpoints: (key, url) => api.get(url),
    postEndpoints: (key, [ url, body ]) => api.post(url, body),
    patchEndpoints: (key, [ url, body ]) => api.patch(url, body),
    deleteEndpoints: (key, url) => api.delete(url)
  }
}

export default withRouter(Fetch)
