module.exports = {
  title: `Bun Top`,
  image: require('./BunTop.png'),
  height: 90,
  cost: 0.25,
  limit: 2,

  nutrition: {
    calories: 80,
    carbs: 14,
    fiber: 1,
    sugar: 2,
    fat: 2,
    protein: 3,
    sodium: 130,
    iron: 3,
  },
  
  collapsedStyle: {
    zIndex: 1,
    maxWidth: `90%`,
  },

  preparation: {
    limit: 2,
  },
}
