import React from 'react'
import styled from 'styled-components'
import * as UI from '../../UI'
import * as Ingredient from '../Ingredient'
import * as MenuItem from '../MenuItem'
import * as utilities from './utilities'

const Display = styled(({ meal, children, ...props }) => {
  const [ dollarAmount, centsAmount ] = utilities.getTotalCost({ meal, toFixedString: true }).split(`.`)

  return (
    <div tabIndex={0} { ...props }>
      <UI.H3>
        <span>
          ${dollarAmount}<span>{centsAmount}</span>
        </span>

        {meal.title}
      </UI.H3>

      <UI.UL>
        {meal.menuItems && meal.menuItems.map((mealMenuItem, mealMenuItemIndex) => {
          const menuItem = MenuItem[mealMenuItem.key]

          return menuItem && (
            <li key={`mealMenuItem_${mealMenuItemIndex}`} title={mealMenuItem.key} style={menuItem.style}>
              <UI.UL>
                {mealMenuItem.ingredientKeys.map((ingredientKey, ingredientIndex) => {
                  const ingredient = Ingredient[ingredientKey]

                  return ingredient && (
                    <li
                      key={`ingredient_${mealMenuItemIndex}_${ingredientIndex}_${ingredientKey}`}
                      style={{
                        marginTop: ingredientIndex ? ingredient.height * -0.4 : 0,
                        zIndex: mealMenuItem.ingredientKeys.length - ingredientIndex,
                        ...ingredient.collapsedStyle
                      }}
                    >
                      <img
                        alt={ingredient.title}
                        src={ingredient.image}
                        style={{ maxHeight: ingredient.height }}
                      />
                    </li>
                  )
                })}
              </UI.UL>
            </li>
          )
        })}
      </UI.UL>

      <div>
        {children}
      </div>
    </div>
  )
})`
  display: block;
  width: 350px;
  max-width: 100%;
  margin: 45px auto;
  border-radius: 3px;
  background: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out, background 0.25s ease-in-out;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);
  }

  > ${UI.H3} {
    height: 55px;
    padding: 15px 10px 15px 15px;
    margin: 0 0 15px;
    border-radius: 3px;
    text-align: left;
    background: ${({ theme }) => theme.colors.primary};
    color: white;

    > span:first-child {
      float: right;
      font-size: 35px;
      line-height: 22px;

      > span {
        display: inline-block;
        vertical-align: top;
        font-size: 20px;
        line-height: 13px;
        margin-left: 2px;
        border-bottom: 1px solid white;
        padding-bottom: 3px;
      }
    }
  }

  > ${UI.UL} {
    position: relative;

    > li {
      text-align: center;
      margin-bottom: 10px;

      &[title='Burger']:not(:only-child) {
        position: relative;
        z-index: 2;
        float: left;
        clear: left;
        width: 57.14%;
        margin-right: -2.86%;

        ~ li[title='Fries'],
        ~ li[title='Drink'] {
          position: relative;
          z-index: 1;
          float: right;
          clear: right;
          width: 45.71%;
        }
      }

      > ${UI.UL} {
        > li {
          position: relative;
          display: block;
          margin: 0 auto;
          padding: 0;

          > img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            max-height: 100px;
          }
        }
      }
    }
  }

  > div {
    clear: both;
    position: relative;
    z-index: 3;
    min-height: 45px;
    padding: 10px;

    > div {
      float: right;
      clear: right;
      font-size: 18px;
      line-height: 20px;
      text-align: right;

      > span {
        display: inline-block;
        vertical-align: middle;
        height: 20px;
        margin-left: 5px;
      }
    }
  }
`

export default Display
