module.exports = {
  title: `Lettuce`,
  image: require('./Lettuce.png'),
  height: 70,
  limit: 4,

  nutrition: {
    calories: 5,
    carbs: 3,
    fiber: 2,
    sugar: 1,
    protein: 1,
    sodium: 8,
    potassium: 69,
    vitaminA: 174,
    vitaminC: 40,
    calcium: 3,
    iron: 5,
  },

  collapsedStyle: {
    maxWidth: `90%`,
  },

  preparation: {
    limit: 4,
  },
}
