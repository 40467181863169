import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import * as UI from '../UI'
import Logo from './Logo'
import * as Session from './Session'

export const ActualHeader = styled(UI.Header)`
  > ${Session.Avatar} {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  > ${UI.Modal} {
    > ${Logo} {
      position: absolute;
      top: 15px;
      left: 15px;
    }
  }
`

const Header = (props) => (
  <ActualHeader { ...props }>
    <Link to='/'>
      <Logo />
    </Link>

    <Session.Menu />
  </ActualHeader>
)

export default Header
