module.exports = {
  ingredientKeys: [
    `TeaSweet`,
    `TeaUnsweet`
  ],
  
  ingredientTypes: [
    `TeaSweet`,
    `TeaUnsweet`
  ]
}
