module.exports = {
  title: `Tomato`,
  image: require('./Tomato.png'),
  height: 28,
  limit: 1,

  nutrition: {
    calories: 10,
    sodium: 1,
    potassium: 47,
    vitaminA: 8,
    vitaminC: 2,
    calcium: 2,
    iron: 0,
  },

  collapsedStyle: {
    maxWidth: `75%`,
  },

  preparation: {
    limit: 1,
  },
}
